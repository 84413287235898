import { createSlice } from "@reduxjs/toolkit";

const responseSlice = createSlice({
    name: 'login',
    initialState: { responses: []},
    reducers: {
        setResponse(state, action) {
            state.responses = action.payload;
        }

    },
});

export const responseActions = responseSlice.actions;

export default responseSlice;