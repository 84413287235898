import { configureStore } from "@reduxjs/toolkit";

import loginSlice from './login-slice'
import responseSlice from './response-slice'

const store = configureStore({
    reducer: {login: loginSlice.reducer, response: responseSlice.reducer},
});

export default store;
